import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { Link } from "gatsby";

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="Seite existiert nicht" />
      <main>
        <title>Seite existiert nicht</title>
        <Link to="/">Zurück zur Startseite</Link>.
      </main>
    </Layout>
  );
};

export default NotFoundPage;
